<template>
  <div id="Message">
    <div class="header">
      <!-- <div  class="list disJust" @click="changePath('/MyQuesAnswer',1)">
        <div >
          <div class="left" style="background: linear-gradient(158deg, #3C94FF 0%, #4CADFF 100%);">
            <van-badge v-if="detail[1]" :content="detail[1]" color="#FF4868" style="z-index:1">
              <img style="width:0.25rem;opacity:0" src="../assets/image/findOut/mess1.png" />
            </van-badge>
            <img style="width:0.25rem;position:absolute;z-index:0" src="../assets/image/findOut/mess1.png" />
          </div>
          <div >
            <p  class="first">问答 </p>
            <p  class="second" >您有{{detail[1] ? detail[1] : 0}}个提问未回答</p>
          </div>
        </div>
        <span>
          <van-icon name="arrow" color="#ECEDF7" />
        </span>
      </div> -->
      <div  class="list disJust" @click="changePath('/SayHelloTo',2)">
        <div >
          <div class="left" style="background: linear-gradient(134deg, #FF7E4E 0%, #FF9544 100%);">
            <van-badge v-if="detail[2]" :content="detail[2]" color="#FF4868" style="z-index:1">
              <img style="width:0.25rem;opacity:0" src="../assets/image/findOut/mess2.png" />
            </van-badge>
            <img style="width:0.25rem;position:absolute;z-index:0" src="../assets/image/findOut/mess2.png" />
          </div>
          <div >
            <p  class="first">打招呼 </p>
            <p  class="second" >您有{{detail[2] ? detail[2] : 0}}条打招呼消息未查看</p>
          </div>
        </div>
        <span>
          <van-icon name="arrow" color="#ECEDF7" />
        </span>
      </div>
      <div  class="list disJust" @click="changePath('/MessageDetail',3)">
        <div >
          <div class="left" style="background: linear-gradient(135deg, #536DFF 0%, #7589FD 100%);">
            <van-badge v-if="detail[3]" :content="detail[3]" color="#FF4868">
              <img style="width:0.25rem;opacity:0" src="../assets/image/findOut/mess3.png" />
            </van-badge>
            <img style="width:0.25rem;position:absolute;z-index:0" src="../assets/image/findOut/mess3.png" />
          </div>
          <div >
            <p  class="first">星球消息 </p>
            <p  class="second" >您有{{detail[3] ? detail[3] : 0}}条星球消息未处理</p>
          </div>
        </div>
        <span>
          <van-icon name="arrow" color="#ECEDF7" />
        </span>
      </div>
      <div  class="list disJust" @click="changePath('/MessageDetail',4)">
        <div >
          <div class="left" style="background: linear-gradient(331deg, #BE9CFA 0%, #A564FB 100%);">
            <van-badge v-if="detail[4]" :content="detail[4]" color="#FF4868">
              <img style="width:0.25rem;opacity:0" src="../assets/image/findOut/mess4.png" />
            </van-badge>
            <img style="width:0.25rem;position:absolute;z-index:0" src="../assets/image/findOut/mess4.png" />
          </div>
          <div >
            <p  class="first">系统通知 </p>
            <p  class="second" >您有{{detail[4] ? detail[4] : 0}}条系统消息未处理</p>
          </div>
        </div>
        <span>
          <van-icon name="arrow" color="#ECEDF7" />
        </span>
      </div>
      <!-- <div  class="list disJust" @click="changePath('/EditMessage')">
        <div style="flex:1">
          <div class="left" style="background:url();background-size:100% 100%">
            <van-badge :content="5" color="#FF4868">
              <img style="width:0.25rem;opacity:0" src="../assets/image/home/image1.png" />
            </van-badge>
          </div>
          <div style="flex:1">
            <p  class="first">问答 <span style="color:#727387">刚刚</span></p>
            <p  class="second" >您有2个提问未回答</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Message",
  data(){
    return{
      show:false,
      detail:'',//详情
    }
  },
  mounted() {
    // 用户会员信息 1:非会员 2:会员 3:已过期 4:已锁定5:官方  //1认证会员
    this.axios({
      method: 'GET',
      url:'/notify/getNotify',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.detail = res.data.data
      }
    })
  },
  methods:{
    changePath(path,type){
      this.axios({
        method: 'PATCH',
        url:'/notify/notifyRead',
        data:{
          type:type
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.path = path+'?type='+type
          console.log(this.path)
          this.$router.push(this.path)
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
#Message {
  padding-bottom: 0.6rem;
  .header{
    overflow: hidden;
    >div:last-child{
      border-bottom: none;
    }
    .list{
      padding: 0.15rem;
      border-bottom: .1px solid #5C5D6E61;
      align-items: center;
      >span{
        >span{
          color: #ECEDF7;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        .first{
          color: #F6F7FD;
          font-size: 0.14rem;
          margin-bottom: 0.04rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          >img{
            height: 0.18rem;
            margin-left: 0.08rem;
          }
        }
        .second{
          font-size: 0.12rem;
          color: #989AB1;
        }
       
        >.left{
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          margin-right: 0.16rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        // {
        //   width: 0.65rem;
        //   height: 0.65rem;
        //   margin-right: 0.2rem;
        //   box-sizing: border-box;
        //   border: 2px solid white;
        //   border-radius: 50%;
        // }
      }
    }
  }
  .disJust{
      display: flex;
      justify-content: space-between;
    }
  >div{
    background: #23252E;
  }
}
</style>